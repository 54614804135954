import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson } from '@/utils'

export const getUsers = (data: any) =>
  request({
    method: 'post',
    url: '/api/users/getUsers',
    data
  })

export const getUsersCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/users/getUsersCount',
    data
  })

export const getAllUsers = (data: any) =>
  request({
    method: 'post',
    url: '/api/users/getAllUsers',
    data
  })

export const getAllUsersCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/users/getAllUsersCount',
    data
  })

export const getUser = (params: any) =>
  request({
    method: 'get',
    url: '/api/users/getUser',
    params
  })

export const saveUser = (data: any) =>
  request({
    method: 'post',
    url: '/api/users/saveUser',
    data
  })

export const saveUserWithMedia = (formData: any) =>
  request.post('/api/users/saveUserWithMedia', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

export const saveUserBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/users/saveUserBulk',
    data
  })

export const changePassword = (data: any) =>
  request({
    method: 'post',
    url: '/api/users/changePassword',
    data
  })

export const deleteUser = (params: any) =>
  request({
    method: 'get',
    url: '/api/users/deleteUser',
    params
  })

export const deleteUserBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/users/deleteUserBulk',
    data
  })

export const forgotPassword = (params: any) =>
  request({
    url: '/api/users/forgotPassword',
    method: 'get',
    params
  })

export const exportUsers = (users: any) => {
  const fields = [
    {
      key: 'first_name',
      type: 'translations',
      transKeys: ['first_name']
    },
    {
      key: 'last_name',
      type: 'translations',
      transKeys: ['last_name']
    },
    {
      key: 'address',
      type: 'translations',
      transKeys: ['address']
    },
    {
      key: 'city',
      type: 'translations',
      transKeys: ['city']
    },
    {
      key: 'description',
      type: 'translations',
      transKeys: ['description']
    },
    { key: 'address_number' },
    { key: 'post_code' },
    { key: 'main_email' },
    { key: 'tax_identification_number' },
    { key: 'identity_card' },
    { key: 'main_phone' },
    { key: 'secondary_phone' },
    { key: 'date_created', type: 'timestamp' },
    { key: 'date_updated', type: 'timestamp' }
  ]

  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, users),
    'user-list'
  )
}
