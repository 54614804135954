

















































import { Component, Vue } from 'vue-property-decorator'
import { Form as ElForm, Input } from 'element-ui'
import { errorMsg, successMsg } from '@/utils'
import { forgotPassword } from '@/api/users'

@Component({
  name: 'ForgotPassword'
})
export default class extends Vue {
  private loginForm = {
    username: ''
  }

  private loginRules = {
    username: [{ required: true, message: this.$t('form.isRequired'), trigger: 'blur' }]
  }

  private loading = false

  mounted() {
    if (this.loginForm.username === '') {
      (this.$refs.username as Input).focus()
    }
  }

  private redirectToLogin() {
    return this.$router.push('/login')
  }

  private handleRecoverPassword() {
    (this.$refs.loginForm as ElForm).validate(async(valid: boolean) => {
      if (!valid) {
        return false
      }
      try {
        this.loading = true
        await forgotPassword(this.loginForm)
        this.loading = false
        await successMsg('login.emailSend')
        await this.redirectToLogin()
      } catch (e) {
        await errorMsg('login.authFailure')
        this.loading = false
      }
    })
  }
}
